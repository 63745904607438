// Editor.jsx
import PropTypes from 'prop-types';
import '../../utils/highlight';
import ReactQuill from 'react-quill';
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, Dialog, IconButton, Zoom } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { ref as reference, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebase';
import fileService from '../../services/file-service/FileService';
import { StyledEditor, deleteButtonStyle } from './styles';
import EditorToolbar, { formats } from './EditorToolbar';
import { handleError } from "../../utils/handleError";
import { pxToRem } from '../../theme/typography';

// Import React for forwardRef in Transition
import React from 'react';

// Define the custom Zoom transition
const Transition = React.forwardRef(function Transition(props, ref) {
  const { transformOrigin, ...other } = props;
  return (
    <Zoom
      ref={ref}
      {...other}
      style={{
        transformOrigin: `${transformOrigin.x + 50}% ${transformOrigin.y + 50}%`,
      }}
      timeout={{ enter: 500, exit: 200 }}
    />
  );
});

const filename = "src/components/editor/Editor.js";

const Editor = forwardRef(({
  id = 'good-question-quill',
  error,
  value,
  onChange,
  simple = false,
  helperText,
  editorStyle,
  sx,
  toolbarActive = true,
  placeholder,
  readOnly = false,
  hideScrollbar = false,
  onClick,
  autoFocus = false,
  readBottomLeftIcon,
  ...other
}, ref) => {
  const [editor, setEditor] = useState(null);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [imageToView, setImageToView] = useState(null); // State to hold image URL
  const [transformOrigin, setTransformOrigin] = useState({ x: 0, y: 0 }); // Default to center

  // Expose editor to parent component
  useImperativeHandle(ref, () => ({
    getEditor: () => editor?.getEditor()
  }));

  useEffect(() => {
    if (autoFocus && editor) {
      const quillEditor = editor.getEditor();
      quillEditor.focus();
      setTimeout(() => {
        const length = quillEditor.getLength();
        quillEditor.setSelection(length, length);
      }, 0);
    }
  }, [autoFocus, editor]);

  const handleImageUpload = ({ file, onProgress, onSuccess, onError }) => {
    const imageRef = reference(storage, `article-images/${file.name + uuidv4()}`);
    const uploadTask = uploadBytesResumable(imageRef, file);

    fileService.upload({
      uploadTaskSnapshot: uploadTask,
      onProgress,
      onSuccess: async (uploadTaskSnapshot) => {
        const downloadURL = await getDownloadURL(uploadTaskSnapshot.snapshot.ref);
        onSuccess(downloadURL);
      },
      onError,
    });
  };

  const handleImageInsert = async (e) => {
    const file = e.target.files[0];
    if (file) {
      handleImageUpload({
        file,
        onProgress: (progress) => {
          console.log(`Upload is ${progress}% done`);
        },
        onSuccess: (downloadURL) => {
          const quillEditor = editor.getEditor();
          const range = quillEditor.getSelection();
          quillEditor.insertEmbed(range.index, 'image', downloadURL);
        },
        onError: (error) => {
          handleError({
            error,
            message: "Error uploading image",
            filename,
            functionName: "handleImageInsert",
          });
        },
      });
    }
  };

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = handleImageInsert;
  };

  useEffect(() => {
    if (editor) {
      const quillEditor = editor.getEditor();
      if (quillEditor && quillEditor.getModule('toolbar')) {
        quillEditor.getModule('toolbar').addHandler('image', imageHandler);
      }
      quillEditor.root.addEventListener('click', handleImageClick);
      return () => {
        quillEditor.root.removeEventListener('click', handleImageClick);
      };
    }
  }, [editor, readOnly]); // Include readOnly in dependency array

  const handleImageClick = (e) => {
    const img = e.target.closest('img');
    if (img) {
      if (readOnly) {
        // Capture the image's bounding rect
        const rect = img.getBoundingClientRect();
        // Calculate center position as a percentage of the viewport
        const centerX = (rect.left + rect.width / 2) / window.innerWidth;
        const centerY = (rect.top + rect.height / 2) / window.innerHeight;
        setTransformOrigin({ x: centerX, y: centerY });
        setImageToView(img.src);
      } else {
        const rect = img.getBoundingClientRect();
        setImageToDelete({
          element: img,
          top: rect.top,
          left: rect.left + img.offsetWidth - 20,
        });
      }
    } else {
      setImageToDelete(null);
    }
  };

  const handleDeleteImage = async () => {
    if (imageToDelete) {
      const currentContent = editor.getEditor().root.innerHTML;
      const div = document.createElement('div');
      div.innerHTML = currentContent;
      const img = div.querySelector(`img[src="${imageToDelete.element.src}"]`);
      if (img) {
        img.remove();
      }
      const newContent = div.innerHTML;
      onChange(newContent);
      const imageRef = reference(storage, imageToDelete.element.src);
      await fileService.delete({ ref: imageRef });
      setImageToDelete(null);
    }
  };

  const modules = {
    toolbar: !readOnly && toolbarActive ? {
      container: `#${id}`,
    } : false,
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <StyledEditor
        readOnly={readOnly}
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          borderRadius: 0,
          backgroundColor: (theme) => !readOnly ? theme.palette.common.white : "inherit",
          ...sx,
        }}
        hideScrollbar={hideScrollbar}
        data-qa-id={`editor-toolbar-container-${id}`}
        onClick={onClick}
      >
        <ReactQuill
          ref={(el) => setEditor(el)}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          readOnly={readOnly}
          style={{
            ...editorStyle,
          }}
          {...other}
        />
        {!readOnly && toolbarActive && <EditorToolbar id={id} isSimple={simple} />}
        {readOnly && readBottomLeftIcon && (
          <Box
            sx={{
              position: 'absolute',
              bottom: pxToRem(16),
            }}
          >
            {readBottomLeftIcon}
          </Box>
        )}
      </StyledEditor>
      {!readOnly && imageToDelete && (
        <button
          className="delete-button"
          onClick={handleDeleteImage}
          style={{
            ...deleteButtonStyle,
            top: `${imageToDelete.top}px`,
            left: `${imageToDelete.left}px`,
          }}
        >
          &times;
        </button>
      )}
      {imageToView && (
        <Dialog
          open={Boolean(imageToView)}
          onClose={() => setImageToView(null)}
          fullScreen
          TransitionComponent={(props) => <Transition {...props} transformOrigin={transformOrigin} />} // Pass transformOrigin to Transition
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          }}
        >
          <IconButton
            onClick={() => setImageToView(null)}
            aria-label="Close image view"
            sx={{
              position: 'absolute',
              top: pxToRem(16),
              left: pxToRem(16), // Changed to right for better UX
              zIndex: 1,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2,
            }}
          >
            <img
              src={imageToView}
              alt="Full Size"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: 8,
                boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
                transition: 'transform 0.5s ease',
              }}
            />
          </Box>
        </Dialog>
      )}
      {helperText && helperText}
    </>
  );
});

Editor.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  editorStyle: PropTypes.object,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.object,
  toolbarActive: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  hideScrollbar: PropTypes.bool,
  onClick: PropTypes.func,
  autoFocus: PropTypes.bool,
  readBottomLeftIcon: PropTypes.node,
  className: PropTypes.string,
};

Editor.defaultProps = {
  placeholder: "Write something awesome",
};

export default Editor;
